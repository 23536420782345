import React from 'react';
import circle from '../../assets/sidebarShapes/dropCircle.svg';
import triangle from '../../assets/sidebarShapes/dropTriangle.svg';
import rectangle from '../../assets/sidebarShapes/dropRectangle.svg';
import ellipseShape from '../../assets/sidebarShapes/ellipse.svg';
import diamondShape from '../../assets/sidebarShapes/diamond.svg';
import OvalShape from '../../assets/sidebarShapes/oval.png';
import parallelogramShape from '../../assets/sidebarShapes/parallelogram.svg';
import { MindMapContext } from '../../Context/contex';
import './sidebar.css';

const shapes = [
    { src: circle, alt: 'circle-shape', title: 'Add circle', nodeType: 'circle' },
    { src: triangle, alt: 'triangle-shape', title: 'Add triangle', nodeType: 'triangle' },
    { src: rectangle, alt: 'rectangle-shape', title: 'Add rectangle', nodeType: 'rectangle' },
    { src: parallelogramShape, alt: 'parallelogram-shape', title: 'Add parallelogram', nodeType: 'parallelogram' },
    { src: ellipseShape, alt: 'ellipse-shape', title: 'Add ellipse', nodeType: 'ellipse', size: 34 },
    { src: diamondShape, alt: 'diamond-shape', title: 'Add diamond', nodeType: 'diamond',size:35 },
    { src: OvalShape, alt: 'oval-shape', title: 'Add oval', nodeType: 'oval',size:35 }
];

const Sidebar = () => {
    const { isReadOnly, darkMode } = MindMapContext();

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className={`sidebar ${darkMode ? 'dark' : ''}`} style={{ pointerEvents: isReadOnly ? 'none' : 'all' }}>
            {shapes.map(({ src, alt, title, nodeType, size }) => (
                <img
                    key={nodeType}
                    width={size || 30}
                    src={src}
                    alt={alt}
                    title={title}
                    onDragStart={(event) => onDragStart(event, nodeType)}
                    draggable
                />
            ))}

        </div>
    );
};

export default Sidebar;
