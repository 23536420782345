import React, { useEffect, useRef, useState } from 'react';
import 'reactflow/dist/style.css';
import './index.css';
import { Button, Drawer, Form, Input, Checkbox, } from 'antd';
import BgColorDropdown from '../BgColorDropdown/bgDropdown';
import ShapeDropdown from '../Shapes/ShapeDropdown';
import { MindMapAPI } from '../../Api/api';
import { emitNodeDelete, emitNodeUpdate } from '../../Socket/socket';
import { debounce } from "lodash";
import { MindMapContext } from '../../Context/contex';
import { CloseOutlined } from '@mui/icons-material';

const EditNodeModal = ({
  selectedNodeId,
  setNodes,
  setEdges,
  editModalOpen,
  setNodeName,
  setNodeBg,
  setNodeHidden,
  nodeName,
  nodeBg,
  nodeHidden,
  setEditModalOpen,
  setSelectedShape,
  selectedShape,
  setTextBg,
  textBg,
  nodes,
  selectedNode,
  edges,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { setIsDocumentChanged } = MindMapContext()

  const updateNodeName = useRef(debounce((payload) => {
    emitNodeUpdate(payload);
  }, 400)).current;

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            label: nodeName,
          };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      name:nodeName
    }
    updateNodeName(payload);
  }, [nodeName]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.type = selectedShape;
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      type:selectedShape
    }
    emitNodeUpdate(payload);
  }, [selectedShape]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            nodeBg: nodeBg,
          };
          node.style = { ...node.style, backgroundColor: nodeBg };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      bg:nodeBg
    }
    emitNodeUpdate(payload);
  }, [nodeBg]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            textBg: textBg,
          };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      textBg:textBg
    }
    emitNodeUpdate(payload);
  }, [textBg]);

  // useEffect(() => {
  //   setNodes((nds) =>
  //     nds.map((node) => {
  //       if (node.id === selectedNodeId) {
  //         node.hidden = nodeHidden;
  //       }
  //       return node;
  //     })
  //   );
  //   setEdges((eds) =>
  //     eds.map((edge) => {
  //       if (edge.id === 'e1-2') {
  //         edge.hidden = nodeHidden;
  //       }

  //       return edge;
  //     })
  //   );
  // }, [nodeHidden]);

  const handleCancel = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = selectedNode.data;
          node.type = selectedNode.type;
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      PrevData:selectedNode.data,
      prevNodeType:selectedNode.type
    }
    emitNodeUpdate(payload);
    setEditModalOpen(false);
  };
  const handleDelete = () => {
    const filteredEdges = edges.filter(
      (edge) => edge.source !== selectedNodeId && edge.target !== selectedNodeId
    );
    setEdges(filteredEdges);
    setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
    edges.forEach((edge) => {
      if (edge.source === selectedNodeId || edge.target === selectedNodeId) {
        if (edge.id) {
          MindMapAPI.deleteEdge(edge.id);
        }
      }
    });
    MindMapAPI.deleteNode(selectedNodeId);
    emitNodeDelete(selectedNodeId);
    setIsDocumentChanged(true);
    setEditModalOpen(false);
  };


  const updateNode = (selectedNodeId) => {
    let payload = {
      data: {
        label: nodeName,
        nodeBg: nodeBg,
        textBg: textBg,
      },
      type: selectedShape
    }
    MindMapAPI.updateNode(selectedNodeId, payload);
    // let emitPayload ={
    //   id:selectedNodeId,
    //   newData:payload.data,
    //   newNodeType:payload.type
    // }
    // emitNodeUpdate(emitPayload);
    setIsDocumentChanged(true);
    setEditModalOpen(false);
  }

  return (
    <Drawer
      title="Edit Node"
      placement="right"
      className='edit-node-drawer no-blur-container'
      open={editModalOpen}
      onClose={handleCancel}
      maskClosable={false}
      closeIcon={null}
      style={{ height: 'fit-content' }}
      extra={
        <Button className='close-icon' onClick={handleCancel} icon={<CloseOutlined />} />
      }
      headerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '2px 16px'
      }}
    >
      <Form className='edit-node-form' >
        {/* <div className="" style={{ marginTop: '-10px' }}> */}
        <Form.Item label="Name:" className='form-label'>
          <Input placeholder='Enter Name' value={nodeName} onChange={(evt) => setNodeName(evt.target.value)} />
        </Form.Item>
        <Form.Item label='Background Color:' className='form-label'>
          <div className='color-picker'>
            <BgColorDropdown setNodeBg={setNodeBg} setIsSelected={setIsSelected} />
          </div>
        </Form.Item>
        <Form.Item label='Text Color:' className='form-label'>
          <div className='color-picker'>
            <BgColorDropdown setNodeBg={setTextBg} setIsSelected={setIsSelected} />
          </div>
        </Form.Item>
        <Form.Item label='Shapes:' className='form-label'>
          <ShapeDropdown selectedShape={selectedShape} setSelectedShape={setSelectedShape} />
        </Form.Item>
        {/* <Form.Item label='Hidden:'>
            <div className="">
              <Checkbox
                type="checkbox"
                checked={nodeHidden}
                onChange={(evt) => setNodeHidden(evt.target.checked)}
              />
              {nodeHidden && <VisibilityOffIcon />}
            </div>
          </Form.Item> */}
        <div className='btn-container'>
          <Form.Item>
            <Button
              type="primary"
              size="medium"
              className="btn-style"
              style={{ background: '#526bb1' }}
              block
              htmlType="submit"
              onClick={() => {
                updateNode(selectedNodeId);
              }}
            >
              Update
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="medium"
              className="btn-style"
              style={{ background: 'rgb(219, 82, 82)' }}
              block
              onClick={() => {
                handleDelete();
              }}
              danger>
              Delete
            </Button>
          </Form.Item>
        </div>
        {/* </div> */}
      </Form>
    </Drawer>
  );
};

export default EditNodeModal;
