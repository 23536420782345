import React, { useRef, useState, useContext } from "react";
import { Modal, Dropdown, message, Form, Input } from "antd";
import importExportIcon from "../../assets/import-export.svg";
import { saveAs } from "file-saver";
import { MindMapContext } from "../../Context/contex";
import { MindMapAPI } from "../../Api/api";
import { useParams } from "react-router-dom";

const ImportExportMenu = () => {
  const { documentId } = useParams();
  const { documentInfo, nodes, edges, setNodes, setEdges } = MindMapContext();
  const fileInputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [form] = Form.useForm();


  const handleMenuClick = (key) => {
    setIsDropdownOpen(false); // Close dropdown before any action
    if (key === "import") {
      fileInputRef.current.click();
    } else if (key === "export.mindmap") {
      form.setFieldsValue({ fileName: documentInfo?.name || "Untitled document" });
      setFileName(documentInfo?.name || "");
      setIsModalVisible(true);
    }
  };
  

  const handleExport = () => {
    form
      .validateFields()
      .then(() => {
        try {
          const content = {
            nodes: nodes.map(({ __v, _id, ...rest }) => rest),
            edges: edges.map(({ __v, _id, ...rest }) => rest),
          };
          const blob = new Blob([JSON.stringify(content, null, 2)], { type: "application/json" });
          saveAs(blob, `${fileName}.mindmap`);
          message.success(`File successfully exported as ${fileName}.mindmap`);
          setIsModalVisible(false);
        } catch (exportError) {
          console.error("Export failed:", exportError);
          message.error("An error occurred during export. Please try again.");
        }
      })
      .catch((validationError) => {
        console.error("Validation failed:", validationError);
        message.error("Please enter a valid file name.");
      });
  };
  

  const handleImport = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const text = await file.text();
        const importedMap = JSON.parse(text);
        if (importedMap) {
          Modal.confirm({
            title: "Confirm",
            content: "Do you want to overwrite?",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              const { nodes, edges } = importedMap;
              nodes && setNodes([...nodes]);
              edges && setEdges([...edges]);
              MindMapAPI.saveImportDataToDb({ nodes: nodes, edges: edges, documentId: documentId });
              message.success("Mindmap imported successfully!");
            },
            onCancel: () => {
              event.target.value = "";
            },
          });
        }
      }
    } catch (error) {
      message.error("Error importing slides. Please check the file format.");
      console.error("Error importing slides:", error);
    } finally {
      event.target.value = "";
    }
  };

  const menuItems = [
    {
      key: "import",
      label: "Import",
    },
    {
      key: "export",
      label: "Export",
      children: [
        {
          key: "export.mindmap",
          label: ".mindmap",
        },
      ],
    },
  ];

  const handleFileNameChange = (e) => {
    setFileName(e.target.value)
  }

  return (
    <>
      <Dropdown
        menu={{
          items: menuItems,
          onClick: ({ key }) => handleMenuClick(key),
        }}
        trigger={["click"]}
        open={isDropdownOpen}
        onOpenChange={setIsDropdownOpen}
      >
        <div style={{ cursor: 'pointer', marginTop: '8px', marginLeft: "8px" }} title={"Import/Export"}>
          <img src={importExportIcon} width={24} alt="Import/Export" />
        </div>
      </Dropdown>
      <input
        type="file"
        accept=".mindmap"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleImport}
      />
      <Modal
        title="Set File Name"
        open={isModalVisible}
        onOk={handleExport}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label="File Name"
            name="fileName"
            rules={[
              {
                required: true,
                message: "Please enter a file name.",
              },
              {
                pattern: /^[^<>:;,?"*|/\\]+$/,
                message: "File name contains invalid characters.",
              },
            ]}
          >
            <Input
              value={fileName}
              onChange={handleFileNameChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportExportMenu;
