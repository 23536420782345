import classes from './UserAvatar.module.css';
import { useState } from "react";
import { Image, Badge } from 'antd';
import { MindMapContext } from '../../Context/contex';

export const UserAvatar = () => {
  const { userInfo, isReadOnly, documentInfo } = MindMapContext();
  const [modalOpen, setModalOpen] = useState(false);

  const right = {
    right: !isReadOnly ? "Collaborator" : "Reader"
  }
  const role = {
    role: documentInfo?.createBy == userInfo.userId ? "Creator" : "Member"
  }

  const getInitials = () => {
    const names = userInfo.name && userInfo.name.split(" ");
    if (Array.isArray(names) && names.length > 0) {
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  };

  const renderProfileImage = (isDetailModalOpen) => {
    if (userInfo.image) {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '40px' : '20px',
            bottom: '-4px',
            width: isDetailModalOpen ? '12px' : '10px',
            height: isDetailModalOpen ? '12px' : '10px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block'
          }}
          dot={true}
        >
          <Image
            src={userInfo.image}
            width={isDetailModalOpen ? '75px' : "32px"}
            height={isDetailModalOpen ? '75px' : "32px"}
            style={{ borderRadius: "50%" }}
            preview={isDetailModalOpen ? isDetailModalOpen : false}
          />
        </Badge>
      );
    } else {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '30px' : '22px',
            bottom: isDetailModalOpen ? '-4px' : '-2px',
            width: isDetailModalOpen ? '12px' : '10px',
            height: isDetailModalOpen ? '12px' : '10px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block'
          }}
          dot={true}
        >
          <div className={classes["no-profileImage-div"]}
            style={{
              width: isDetailModalOpen ? '65px' : '36px',
              height: isDetailModalOpen ? '65px' : '36px',
              fontSize: isDetailModalOpen ? '26px' : '14px'
            }}
          >
            {getInitials()}
          </div>
        </Badge>
      );
    }
  };
  return (
    <div
      onMouseLeave={() => setModalOpen(false)}
      onMouseEnter={() => setModalOpen(true)}
    >
      {renderProfileImage(false)}
      {modalOpen && (
        <div className={classes['custom-modal-container']}>
          <div className={classes['modal-profile-image']}>
            {renderProfileImage(true)}
          </div>
          {/* <span style={{fontWeight:'600', fontSize:'26px'}}>{userInfo.name}</span>
          <span style={{fontWeight:'600', fontSize:'16px', marginTop:'-10px'}}>{userInfo.designation}</span>
          <span style={{marginTop:'2px', color:'blue'}}className={classes['user-detail-text']}>{userInfo.email}</span> */}
          <div className={classes['user-detail-container']}>
            <div>
              <span className={classes['user-detail-label']}>Name: </span>
              <span className={classes['user-detail-text']}>{userInfo.name}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Email: </span>
              <span className={classes['user-detail-text']}>{userInfo.email}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Rights: </span>
              <span className={classes['user-detail-text']}>{right.right}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Role: </span>
              <span className={classes['user-detail-text']}>{role.role}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
