import React, { useState, useEffect } from "react";
import logo from "../../assets/Mind-Logo.png";
import { MindMapContext } from "../../Context/contex";
import DownloadButton from "../DownloadImageButton/downloadButton";
import privateIcon from '../../assets/lock.svg';
import publicIcon from '../../assets/world.svg';
import './Header.css'
import PrintMindMap from "../Print/printMindMap";
import CompleteDocument from "../CompleteDoc/completeDoc";
import SavingStatus from "../SavingStatus/savingStatus ";
import ImportExportMenu from "../ImportExport";

const Title = () => {
    const { documentInfo,isReadOnly } = MindMapContext();
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        const handleOfflineStatus = () => {
            setOffline(!window.navigator.onLine);
        };

        window.addEventListener("offline", handleOfflineStatus);
        window.addEventListener("online", handleOfflineStatus);

        // Cleanup the event listeners when the component unmounts
        return () => {
            window.removeEventListener("offline", handleOfflineStatus);
            window.removeEventListener("online", handleOfflineStatus);
        };
    }, []); // Empty dependency array ensures that this effect runs only once on mount

    const isMobile = window.screen.width < 769

    return (<>
        <div
            style={{
                top: 15,
                left: 25,
                width: "max-content",
                height: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                boxShadow: 'rgb(101 92 92 / 50%) 0px 3px 8px',
                borderRadius: '5px',
                padding: isMobile ? 14 : 20,
                position: "relative",
                zIndex: 1,
            }}
        >
            <img
                src={logo}
                width={isMobile ? "20px" : "30px"}
                height={isMobile ? "20px" : "30px"}
                style={{ marginRight: isMobile ? 10 : 20 }}
            />
            <div
                style={{
                    height: 30,
                    width: 2,
                    marginRight: isMobile ? 5 : 20,
                    backgroundColor: "#757D86",
                }}
            ></div>
            <h1
                style={{
                    fontSize: isMobile ? 10 : 15,
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    marginRight: 10,
                    width: 'max-content',
                    maxWidth: '350px',
                    overflow: "hidden",
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginRight: isMobile ? 12 : 20,
                }}
            >
                {documentInfo?.name ? documentInfo.name : "Untitled Mind Map"}

            </h1>
            <div>
                {documentInfo?.privacyId == 2 ? (
                    <><img src={privateIcon} alt='Private documnet' style={{ width: '10px', paddingBottom: 2, marginRight: 10 }} />
                    </>
                )
                    : (<> <img src={publicIcon} alt='Public documnet' style={{ width: '12px', paddingBottom: 1, marginRight: 10 }} /></>)}
            </div>
            <div
                style={{
                    height: 30,
                    width: 1,
                    marginRight: 20,
                    backgroundColor: "#757D86",
                }}
            ></div>
            <span style={{ marginTop: '-62px', marginRight: 20, }}>
                <DownloadButton />
            </span>
            <ImportExportMenu/>
            <div>
                <PrintMindMap />
            </div>
            {
                !isReadOnly && <CompleteDocument />
            }
           <SavingStatus/>
        </div> {offline &&
            <div className="offlineStatus"
            >
                <h5>No Internet, Your changes are not being saved</h5>
            </div>
        }

    </>
    );
};

export default Title;
